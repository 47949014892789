<template>
<div id="app">
	<div class="main-content">
		
		<div v-if="transaction">

			<div class="flex column" style="margin: 0px 20px 30px 20px">

				<div class="flex column" style="align-items: center">
					<img :src="`${url}storage/logos/${transaction.local.logotype}`" width="100" style="border-radius: 10px">
					<div class="bold" style="margin: 10px 0px; font-size: 20px">{{transaction.local.name}}</div>
					<div style="font-size: 14px; margin: 10px 0px 20px; line-height: 1.1">Te ayudamos a dividir la cuenta de tu pedido de un total de <span class="secondary">$</span><span class="bold">{{ToNumber(transaction.total)}}</span></div>
				</div>

				<div style="font-size: 18px; text-align: center; font-weight: 500">¿En cuantas personas dividiremos la cuenta?</div>
				
				<div class="flex column item" style="margin: 30px 0px 40px">
					<div class="flex" style="justify-content: center; align-items: center; margin-bottom: 10px">
						<i class="icon icon-remove selectable" style="font-size: 24px; line-height: 0" @click="customersQuantity > 2 ? customersQuantity-- : null"></i>
						<div class="flex column"  style="text-align: center; width: 100px; font-size: 32px;">
							<div style="line-height: 1">{{customersQuantity}}</div>
							<div class="secondary" style="font-size: 10px">Personas</div>
						</div>
						<i @click="customersQuantity < 20 ? customersQuantity++ : null" class="icon icon-add selectable" style="font-size: 24px; line-height: 0"></i>
					</div>
				
					<div style="display: flex; justify-content: center; flex-wrap: wrap">
						<div v-for="i in customersQuantity" style="margin-left: -8px; margin-top: 4px"
						:class="`customer-selection customer-${i} selected`" :key="`customer-${i}`">
							<i :class="`icon icon-${customersType[i-1]}`"></i>
						</div>
					</div>
				</div>

				<div style="line-height: 1.1; font-size: 14px">Ahora asigna un ícono a cada persona y luego selecciona a que persona corresponde cada ítem presionando el ícono que corresponde</div>

			</div>

			<div :class="`item ${!SelectedCount(item) ? 'no-items' : ''}`" v-for="(item, itemIndex) in transaction.items" :key="`item-${itemIndex}`">
				<div class="flex">
					<div class="fillw">{{item.title}}</div>
					<div><span class="secondary">$</span><span class="bold">{{ToNumber(item.price)}}</span></div>
				</div>
				<div v-for="(modifier, modIndex) in item.modifiers" :key="`mod-${modIndex}-item${itemIndex}`" style="opacity: 0.7; font-size: 10px">
					<span v-if="modifier.group">{{modifier.group}} - </span>
					<span>{{modifier.title}}</span>
				</div>
				<div class="customers-selection" style="padding: 10px 0px" @scroll="CustomersScrollChanged" ref="customersSelection">
					<div v-for="i in customersQuantity" @click="item['selected'+(i-1)] ? $delete(item, 'selected'+(i-1)) : $set(item, 'selected'+(i-1), true)"
					:class="`customer-selection customer-${i} ${item['selected'+(i-1)] ? 'selected' : 'unselected'} selectable`" :key="`customer-${i}-item-${itemIndex}`">
						<i :class="`icon icon-${customersType[i-1]}`"></i>
					</div>
				</div>
				<div class="flex" style="opacity: 0.7; font-size: 10px; justify-content: space-between">
					<div class="selectable" @click="UnselectAll(item)">Deseleccionar todos</div>
					<div class="selectable" @click="SelectAll(item)">Seleccionar todos</div>
				</div>
			</div>

			<div v-if="unselectedItems" style="margin-top: 40px; text-align: center; font-weight: 600; color: #ff3377">¡Hay items sin nadie asignado!</div>

			<div style="text-align: center; margin: 30px 0px 30px 0px; font-weight: bold; font-size: 20px">El total para cada uno es</div>
			

			<div :class="`item customer-${index+1}`" v-for="(customer, index) in customersParts" :key="`customer-${index}`">
				<div class="fillw" style="text-align: center; font-size: 36px">
					<i :class="`icon icon-${customersType[index]}`"></i>
				</div>
				<div class="flex column" style="margin: 16px 0px; font-size: 10px; opacity: 0.7">
					<div v-if="!customer.items.length" style="text-align: center">¡Ningún item seleccionado!</div>
					<div v-for="(item, indexItem) in customer.items" :key="`customer-${index}-item-${indexItem}`" class="flex">
						<div class="fillw">{{item.item.title}} <span>({{item.percentage}} de ${{ToNumber(item.item.price)}})</span></div>
						<div>${{ToNumber(item.part)}}</div>
					</div>
				</div>
				<div class="flex">
					<div class="fillw bold">Total</div>
					<div><span class="secondary">$</span><span class="bold">{{ToNumber(customer.total)}}</span></div>
				</div>
				<div class="flex" style="font-weight: 300; font-size: 14px">
					<div class="fillw">Total con propina 10%</div>
					<div><span class="secondary">$</span><span>{{ToNumber(parseInt(customer.total * 1.1))}}</span></div>
				</div>
			</div>

		</div>
		<div v-else style="height: 80vh; display: flex; align-items: center; justify-content: center; opacity: 0.85">
			<div v-if="loading" class="flex column" style="align-items: center; justify-content: center">
				<i class="icon icon-semi-circle spin" style="line-height: 0; font-size: 48px; margin-bottom: 20px; opacity: 0.5"></i>
				Buscando el pedido...
			</div>
			<div v-else class="flex column" style="align-items: center; justify-content: center; margin: 0px 20px; text-align: center">
				<i class="icon icon-sad" style="line-height: 0; font-size: 48px; margin-bottom: 20px; opacity: 0.5"></i>
				No hemos podido encontrar el pedido que buscas o ya no está disponible
			</div>
		</div>

	</div>

	<a href="https://influyeapp.cl" style="margin-top: 20px">Powered by Influye</a>

</div>
</template>

<script>
const axios = require('axios');
export default {
	name: 'App',
	mounted() {
		var code = window.location.pathname.replace('/', '');

		if(code.indexOf('-') > -1)
		{
			var server = code.split('-')[0];
			var port = parseInt(server);
            if(Number.isInteger(port))
                this.url = `http://localhost:${port}/`;
            else
			{
				if(server == 'DEFAULT') this.url = process.env.VUE_APP_URL;
                else this.url = `https://${server}.influye.app/`;
			}
			code = code.split('-')[1];
		}

		axios.get(`${this.url}receipt/${code}`).then(res => {
			console.log(res.data);
			this.transaction = res.data;
			this.loading = false;
		}).catch(err => {
			console.log(err);
			this.loading = false;
		});
	},
	data() {
		return {
			url: process.env.VUE_APP_URL,
			loading: true,
			transaction: null,
			customersQuantity: 2,
			customersType: [
				'dog', 'cat', 'monkey', 'pig', 'mouse', 'frog', 'butterfly' ,'fox', 'bee', 'crab', 'sheep', 'parrot', 'spider', 'elephant', 'snail', 'whale', 'rabbit', 'turtle', 'ladybug', 'fish'
			],
		}
	},
	methods: {
		CustomersScrollChanged(event) {
			//console.log(event);
			var target = event.target;
			var x = target.scrollLeft;
			
			this.$refs.customersSelection.forEach(el => {
				if(el !== target)
				{
					el.scrollLeft = x;
				}
			});
		},
		SelectAll(item)
		{
			for(var i = 0; i < 20; i++)
			{
				this.$set(item, `selected${i}`, true);
			}
		},
		UnselectAll(item)
		{
			for(var i = 0; i < 20; i++)
			{
				this.$delete(item, `selected${i}`);
			}
		},
		SelectedCount(item)
		{
			var count = 0;
			for(var i = 0; i < this.customersQuantity; i++)
				if(item[`selected${i}`]) count++;
			return count;
		},
		ToNumber(number)
		{
			if(number === null || number === undefined || isNaN(number)) return '';
			number = parseFloat(number);
			number = number.toString();
			var parts = number.split('.');
			return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (parts.length > 1 ? `,${parts[1]}` : '');
		},
  	},
	computed: {
		customersParts() {
			if(!this.transaction) return null;
			var customers = [];
			for(var i = 0; i < this.customersQuantity; i++)
			{
				customers.push({
					total: 0,
					items: [],
				});
			}

			this.transaction.items.forEach(item => {
				var count = this.SelectedCount(item);
				
				if(count > 0)
				{
					var divided = item.price / count;
					var offset = 0;
					if(parseInt(divided) != parseFloat(divided))
					{
						divided = parseInt(divided);
						offset = item.price - (divided * count);
					}

					for(var i = 0; i < this.customersQuantity; i++)
					{
						if(item[`selected${i}`])
						{
							customers[i].total += divided + offset;
							customers[i].items.push({
								item: item,
								part: divided + offset,
								percentage: parseInt(100 / count) + '%'
							});
							offset = 0;
						}
					}

				}

			});

			return customers;
		},
		unselectedItems() {
			if(!this.transaction) return 0;
			var count = 0;
			this.transaction.items.forEach(item => {
				if(!this.SelectedCount(item)) count++;
			});
			return count;
		}
	}
}
</script>

<style lang="scss">
@import './fonts/icons/styles.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body {
	padding: 0px;
	margin: 0px;
}
#app {
	background-color: rgba(0, 0, 70, 0.02);
	font-family: 'Poppins', Helvetica, Arial, sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 0px;
}
.main-content {
	width: 100%;
	max-width: 800px;
}
.flex {
	display: flex;
}
.column {
	flex-direction: column;
}
.fillw {
	width: 100%;
}
.bold {
	font-weight: bold;
}
.secondary {
	font-size: 80%;
	opacity: 0.7;
}
.item {
	background-color: white;
	padding: 20px;
	border-radius: 20px;
	margin-bottom: 20px;
	box-shadow: 0px 7px 10px 5px rgba(0, 0, 70, 0.08);
	
	&.no-items {
		box-shadow: 0px 7px 10px 5px rgba(200, 0, 0, 0.1);
		outline: 3px solid rgba(200, 0, 0, 0.05);
	}
}
.customer-selection {
	font-size: 24px;
	color: rgba(0, 0, 70, 0.75);
	width: 42px;
	height: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 0;
	border-radius: 50%;
	flex-shrink: 0;

	&.unselected {
		color: rgba(0, 0, 70, 0.4);
		background-color: rgba(0, 0, 70, 0.05);	
	}

	&.selected {
		animation: anim-pop 0.25s linear;
	}

}
.selectable {
	cursor: pointer;
	user-select: none;
}
.customer-1 {
	background-color: #8abade;
}
.customer-2 {
	background-color: #f59598;
}
.customer-3 {
	background-color: #90cfb5;
}
.customer-4 {
	background-color: #f9f08d;
}
.customer-5 {
	background-color: #cdb3d5;
}
.customer-6 {
	background-color: #ccac90;
}
.customer-7 {
	background-color: #e0e88c;
}
.customer-8 {
	background-color: #fbdede;
}
.customer-9 {
	background-color: #bbdcad;
}
.customer-10 {
	background-color: #febd7d;
}
.customer-11 {
	background-color: #b3b4da;
}
.customer-12 {
	background-color: #f4f1b0;
}
.customer-13 {
	background-color: #bacab2;
}
.customer-14 {
	background-color: #efa796;
}
.customer-15 {
	background-color: #a6c7ea;
}
.customer-16 {
	background-color: #a9dad1;
}
.customer-17 {
	background-color: #f9a586;
}
.customer-18 {
	background-color: #f8b2bc;
}
.customer-19 {
	background-color: #d291bc;
}
.customer-20 {
	background-color: #a5f8ce;
}

@keyframes anim-pop {
	0% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

.customers-selection {
	width: calc(100% + 40px);
	overflow-x: auto;
	display: flex;
	margin-left: -20px;
	margin-right: -20px;
	padding: 0px 16px;
	
	.customer-selection {
		margin-left: 4px;
		&:first-child {
			margin-left: 20px;
		}
		&:last-child {
			margin-right: 20px;
		}
	}

	
}
@media (max-width: 767px) {
	.customers-selection::-webkit-scrollbar {
		display: none;
	}
}

a {
	color: rgba(0, 0, 0, 0.5);
	text-decoration: none;
	font-size: 14px;
}

.spin {
	animation: anim-spin 1s linear infinite;
}
@keyframes anim-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
